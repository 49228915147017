<template>
  <!-- 作者管理 -->
  <div>
    <el-card style="overflow-y: scroll; height: calc((100vh - 60px) - 120px)">
      <el-button
        @click="addDialog = true"
        size="small"
        type="primary"
        icon="el-icon-plus"
      >
        新增作者
      </el-button>

      <div style="display: flex; flex-wrap: wrap; justify-content: center">
        <el-card
          style="margin-top: 10px; margin-right: 20px; width: 400px"
          v-for="item in list"
          :key="item.id"
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <el-avatar :size="50" :src="item.authorHeadUrl"></el-avatar>
            <span>{{ item.authorName }}</span>
            <!-- 编辑删除 -->
            <div>
              <el-button
                type="primary"
                circle
                size="mini"
                icon="el-icon-edit"
                @click="updateClick(item)"
              ></el-button>
              <el-button
                type="danger"
                circle
                size="mini"
                icon="el-icon-delete"
                @click="deleteClick(item)"
              ></el-button>
            </div>
          </div>
        </el-card>
      </div>
    </el-card>

    <!-- 新增作者弹窗 -->
    <el-dialog title="新增作者" align="center" :visible.sync="addDialog">
      <add-author @afterAdd="afterAdd" />
    </el-dialog>

    <!-- 编辑作者弹窗 -->
    <el-dialog title="编辑作者" align="center" :visible.sync="updateDialog">
      <update-author @afterUpdate="afterUpdate" :updateItem="updateItem" />
    </el-dialog>
  </div>
</template>

<script>
import addAuthor from "./author/addAuthor";
import updateAuthor from "./author/updateAuthor";
export default {
  components: {
    addAuthor,
    updateAuthor,
  },
  data() {
    return {
      addDialog: false,
      updateDialog: false,
      updateItem: null,
      list: [],
    };
  },
  created() {
    this.getAuthorList();
  },
  methods: {
    async getAuthorList() {
      const res = await this.getRequest.getAuthorList();
      this.list = res.data;
    },
    updateClick(item) {
      this.updateItem = item;
      this.updateDialog = true;
    },
    afterAdd() {
      // 新增后
      this.addDialog = false;
      this.getAuthorList();
    },
    afterUpdate() {
      // 编辑后
      this.updateDialog = false;
      this.getAuthorList();
    },
    async deleteClick(item) {
      this.$confirm("确定要删除此作者吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const res = await this.getRequest.deleteAuthor(item.id);
        if (res.respCode === 0) {
          this.getAuthorList();
        }
      });
    },
  },
};
</script>