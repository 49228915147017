<template>
  <div>
    <!-- 更新作者 -->
    <div class="updateAuthor">
      <el-upload
        class="avatar-uploader"
        :action="$config.VUE_APP_URL + $filesUrl[0]"
        :show-file-list="false"
        :on-success="uploadSuccess"
        :headers="{
          Authorization: `bearer ${accessToken}`
        }"
        :data="{
          fileType: 2
        }"
        :before-upload="beforeUpload">
        <img v-if="model.authorHeadUrl" :src="model.authorHeadUrl" class="avatar">
        <div v-else>
          <i class="el-icon-plus avatar-uploader-icon"></i>
          <div>
            作者头像：
          </div>
        </div>
      </el-upload>

      <div style="margin-left: 30px">
        <el-form :model="model">
          <el-form-item label="作者姓名" label-width="100px">
            <el-input v-model="model.authorName" placeholder="作者姓名"></el-input>
          </el-form-item>
          <!-- <el-form-item label="作者介绍" label-width="100px">
            <el-input type="textarea" v-model="model.sys" placeholder="作者介绍"></el-input>
          </el-form-item> -->
        </el-form>
      </div>
    </div>

      <div style="margin-top: 30px">
        <el-button type="primary" size="small" @click="sureUpdate">确认修改</el-button>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    updateItem: {
      type: Object
    }
  },
  data () {
    return {
      accessToken: localStorage.getItem('access_token'),
      model: this.updateItem
    }
  },
  methods: {
    beforeUpload (file) {
      const isPNG = file.type === 'image/png'
      const isJPG = file.type === 'image/jpeg'
      const isGIF = file.type === 'image/gif'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!(isPNG || isJPG || isGIF)) {
        this.$message.error('上传图片只能是 PNG或JPG, GIF格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return (isPNG || isJPG || isGIF) && isLt2M
    },
    uploadSuccess (res) {
      this.model.authorHeadUrl = res.url
    },
    async sureUpdate () {
      // 确认更新
      const res = await this.getRequest.updateAuthor(this.model)
      if (res.respCode === 0) {
        this.$message.success(res.respMsg)
        this.$emit('afterUpdate')
      } else {
        this.$message.error(res.respMsg)
      }
    }
  }
}
</script>

<style lang='scss' scoped>
  .updateAuthor {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>